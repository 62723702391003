<template>
    <div>
        <div class="header_holder text-center ma">
            <div class="rounder_top mb-2">
                
                <div class="card_header ubuntu_b primer">
                    <router-link to="/orders" class="linker">
                        <v-btn icon class="mr-5">
                            <v-icon class="mr-3">fas fa-angle-left</v-icon>
                        </v-btn>
                    </router-link>
                    Narudžbenica #{{order.id}} 
                </div>
            </div>
        </div>
        <div class="white_paper">
            <div class="order_dates mb-2 mt-2">
                <v-row>
                    <v-col cols="6" class="text-left">
                        NARUČENO<br>
                        <span>{{ order.date_created | dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY. HH:mm') }}</span>
                        <template v-if="order.date_delivered==''"><br>ZA <span style="color:red">{{ order.date_delivery | dateParse('YYYY-MM-DD') | dateFormat('DD.MM.YYYY.') }}</span></template>
                   </v-col>
                    <v-col cols="6" class="text-right">
                        <template v-if="order.date_delivered!=''">ISPORUČENO</template><br>
                        <span v-if="order.date_delivered!=''">{{ order.date_delivered | dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY. HH:mm') }}</span>
                    </v-col>
                </v-row>
            </div>
            <v-row class="mb-5">
                <v-col v-for="product in order_items" :key="product.cIdent" cols="12" class="order_item_holder rounder primer mb-8">
                    <div class="product_naslov">{{product.cName}}</div>
                    <v-btn v-if="product.note!=''" class="mx-2 napomena_btn" fab small color="gray" @click="openNapomenaModal(product.note)">
                        <v-icon small color="white">far fa-comment-alt</v-icon>
                    </v-btn>
                    <!--<router-link v-if="product.message_id==0 && userCredentials.reklamacije" :to="`/message_add_form_order/`+order.id+`/`+product.cIdent" class="message_btn">-->
                        <v-btn class="mx-2" fab small color="primary" @click="openObavestenjeModal()">
                            <v-icon small color="white">fas fa-envelope-open</v-icon>
                        </v-btn>
                    <!--</router-link>-->
                    <router-link v-if="parseInt(product.message_id)!=0 && userCredentials.reklamacije" :to="`/message_view/`+parseInt(product.message_id)" class="message_btn read">
                        <v-btn class="mx-2" fab small color="secondary">
                            <v-icon small color="white">fas fa-envelope</v-icon>
                        </v-btn>
                    </router-link>
                    <v-row>
                        <v-col cols="6" class="text-left">
                            {{product.cUM}}
                        </v-col>
                        <v-col cols="6" class="text-right">
                            {{product.nMPC | currency((parseInt(order.date_created.split('-')[0]) >= 2023)?'€':'Kn') }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" class="text-left">
                            {{(order.date_delivered=='')?'NARUČENO':'ISPORUČENO'}}<br>{{product.qty}}/{{product.cUM}}
                        </v-col>
                        <v-col cols="6" class="text-right">
                            IZNOS<br>{{product.total | currency((parseInt(order.date_created.split('-')[0]) >= 2023)?'€':'Kn') }}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <div class="order_dates mb-2 mt-2">
                <v-row>
                    <v-col cols="12" v-if="can_change_date">
                        <v-menu
                            v-model="menu_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    :label="'Promeni datum dostave '+order.date_delivery"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date"
                                first-day-of-week="1"
                                @input="menu_date = false"
                                :min="start"
                                :max="end"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" class="text-left">
                        <v-btn v-if="order.napomena!=''" class="mx-2 float-left" fab small color="gray" @click="openNapomenaModal(order.napomena)">
                            <v-icon small color="white">far fa-comment-alt</v-icon>
                        </v-btn>
                        <!--<router-link v-if="parseInt(order.message)==0 && userCredentials.reklamacije" :to="`/message_add_form_order/`+order.id+`/0`" class="edit_btn float-left">-->
                            <v-btn class="mx-2" fab small color="primary" @click="openObavestenjeModal()">
                                <v-icon small color="white">fas fa-envelope-open</v-icon>
                            </v-btn>
                        <!--</router-link>-->
                        <router-link v-if="parseInt(order.message)!=0 && userCredentials.reklamacije" :to="`/message_view/`+parseInt(order.message)" class="edit_btn read float-left">
                            <v-btn class="mx-2" fab small color="secondary">
                                <v-icon small color="white">fas fa-envelope</v-icon>
                            </v-btn>
                        </router-link>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        UKUPAN IZNOS:<br>
                        <span>{{ order.suma | currency}}</span>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-dialog
          v-model="NapomenaModal"
          max-width="320"
        >
          <v-card>
            <v-card-title class="headline">
                Napomena
                <v-icon small color="gray" @click="closeNapomenaModal()">fas fa-times</v-icon>
            </v-card-title>
            <v-card-text max-height="300">
                {{ napomena }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="primer"
                @click="closeNapomenaModal()"
              >
                Zatvori
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="ObavestenjeModal"
          max-width="320"
        >
          <v-card>
            <v-card-title class="headline">
                Obaveštenje
                <v-icon small color="gray" @click="closeObavestenjeModal()">fas fa-times</v-icon>
            </v-card-title>
            <v-card-text max-height="300">
                Poštovani kupci,<br><br>
                zbog problema u komunikaciji moramo da ukinemo ovaj deo aplikacije. Ukoliko ste već koristili ovaj način komunikacije sa mesnicom. Moramo vas zamoliti da ukoliko ste zaboravili nešto u vašoj narudžbi ili imate neku reklamaciju. Da nam pošaljete naknadnu narudžbu (u slučaju da trebate izmene u narudžbi) ili poruku preko kanala komunikacije.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="primer"
                @click="closeObavestenjeModal()"
              >
                Zatvori
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import { conf } from '@/config.js'
export default {
    props: ['id'],
    data: () => ({
        NapomenaModal:false,
        ObavestenjeModal:false,
        napomena:'',
        today: null,
        start: moment().tz(conf.TZ).add(((moment().tz(conf.TZ).add(-((moment().tz(conf.TZ).utcOffset())/60), 'hours').format('H')<20)?0:1), 'days').toISOString().substring(0, 10),
        end: moment().tz(conf.TZ).utc().add(7, 'days').toISOString().substring(0, 10),
        dateFormatted: null,
        menu_date:false,
    }),
    computed: {
        ...mapGetters({
            order: 'orders/orderDetails',
            userLoggedIn: 'user/loggedIn',
            user: 'user/getUser',
            userCredentials: 'user/getCredentials',
        }),
        order_items(){
            let order = this.order
            let items =  order.order_items.map((item) => {
                let mess = order.messages.find(i => i.cident==item.cIdent)
                let message_id = (mess)?mess.id_no:0
                return {
                    ...item,
                    message_id
                }
            })
            console.log(items)
            return items
        },
        date: {
            get: function () {
                return this.order.date_delivery
            },
            set: function (newValue) {
                this.$store.dispatch('orders/changeOrderDate', {
                    new_date: newValue,
                    order_id:this.order.id
                    //page:this.page,
                    //page_size:this.page_size
                })
            }
        },
        user_type(){
            return (typeof this.user.user_type !== 'undefined')?this.user.user_type:3
        },
        can_change_date(){
            return this.$store.getters['users/getPoslovnice'].length === 1 && this.$store.getters['users/getPoslovnice'][0]['document']!==null && this.user_type!=5
        }
    },
    watch: {
        date () {
            this.$store.dispatch('orders/changeOrderDate', {
                new_date: this.date,
                order_id:this.order.id
            })
            this.dateFormatted = this.formatDate(this.date)
        },
        today(){
            this.todayFormatted = this.formatDate(this.today)
        }
    },
    methods: {
        back(){
            this.$router.go(-1)
        },
        openNapomenaModal(napomena){
            this.NapomenaModal = true
            this.napomena = napomena
        },
        closeNapomenaModal(){
            this.NapomenaModal = false
        },
        openObavestenjeModal(){
            this.ObavestenjeModal = true
        },
        closeObavestenjeModal(){
            this.ObavestenjeModal = false
        },
        computedDateFormatted () {
            return this.formatDate(this.date)
        },
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}.${day}.${year}`
        },
        parseDate (date) {
            if (!date) return null
            const [month, day, year] = date.split('.')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    },
    beforeCreate(){
        this.$store.dispatch('orders/getOrderDetails', this.$route.params.id)
    }
};
</script>
<style scoped>
    .white_paper{
        background-color: rgba(255,255,255,0.9);
        width: 100%;
        padding: 20px 40px 5px 40px;
        display: block;
        border: 1px solid black;
        margin-top: 30px;
    }
    .headline{
        position:relative
    }
    .headline button{
        position:absolute;
        right:10px;
        top:10px;
        font-size: 18px;
    }
    .v-btn:not(.v-btn--round).v-size--default{
        min-width:150px;
    }
    .product_holder{
        position:relative;
        width:100%;
        min-height:80px;
        line-height:20px;
        padding:10px 10px 10px 80px;
    }
    .order_item_holder{
        position:relative;
    }
    .message_btn{
        position:absolute;
        right:55px;
        bottom:-20px;
    }
    .float-left{
        display:block;
        float:left
    }
    .napomena_btn{
        position:absolute;
        right:5px;
        bottom:-20px;
    }
    .product_img{
        position:absolute;
        left:0px;
        top:5px;
        width:70px;
        height:70px;
    }
    .product_title{
        min-height:20px;
        line-height:20px;
        position:relative;
    }
    .product_title span{
        position: absolute;
        right: 0;
        text-align: right;
        display: block;
        top: 0;
    }
    .blur{
        position:relative;
    }
    .blur::after{
        content:"";
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        background: rgba(239,239,239,.5);
    }
</style>>