<template>
    <v-container>
        <OrderDetails/>
    </v-container>
</template>
<script>
import OrderDetails from '../components/OrderDetails.vue'
import {  } from 'vuex'
export default {
    components: { OrderDetails }
}
</script>